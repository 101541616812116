import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

class FPD3DPreview {
	constructor(elemId, opts) {
		opts = undefined ? {} : opts;

		// canvas size
		this.elemId = elemId;
		this.canvasW = opts.width || 1200;
		this.canvasH = opts.height || 800;
		this.imgPath = opts.imgPath || "./img/";
		this.modelPath = opts.modelPath || "./models/";

		this.modelFilename = opts.modelFilename;
		this.cameraZ = opts.cameraZ;
		this.baseMaterialMetalness = opts.baseMaterialMetalness;
		this.baseMaterialRoughness = opts.baseMaterialRoughness;
		this.ambientLightColor = opts.ambientLightColor || 0xcccccc;
		this.materialColor = opts.materialColor;
		this.customMaterialLayers = opts.customMaterialLayers;

		this.modalLoad = typeof opts.modalLoad === "function" ? opts.modalLoad : function () {};

		this.object;
		this.envMap = undefined;
		this.orbitControls = undefined;
		this.renderer = undefined;
		this.scene = undefined;
		this.camera = undefined;
		this.textureMaterial = [];
		this.texture = undefined;
		this.modalCreated = false;

		this.initConfigurator();
	}

	// init the component
	initConfigurator() {
		// setup renderer
		this.renderer = new THREE.WebGLRenderer({ antialias: true });
		this.renderer.setClearColor(0xffffff, 1); // set background color
		this.renderer.colorSpace = THREE.SRGBColorSpace;
		this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
		this.renderer.toneMappingExposure = 1;
		this.physicallyCorrectLights = true;

		const canvasContainer = document.getElementById(this.elemId);

		// adjustments for mobile
		let screenW = canvasContainer.clientWidth;

		if (screenW < 450) {
			this.canvasW = screenW * 2 - 2;
			this.canvasH = (this.canvasW / 3) * 2;
		}

		this.renderer.setSize(this.canvasW, this.canvasH);

		// rendering double size for retina display
		this.renderer.domElement.style.width = this.canvasW / 2 + "px";
		this.renderer.domElement.style.height = this.canvasH / 2 + "px";

		canvasContainer.appendChild(this.renderer.domElement);

		// load environment map
		var pmremGenerator = new THREE.PMREMGenerator(this.renderer);
		pmremGenerator.compileEquirectangularShader();
		//
		new RGBELoader()
			//.setDataType( THREE.UnsignedByteType )
			.setPath(this.imgPath)
			.load("environment_map.hdr", (texture) => {
				// set environment map
				this.envMap = pmremGenerator.fromEquirectangular(texture).texture;
				// this.envMap = pmremGenerator.fromCubemap( texture ).texture;
				// this.scene.background = this.envMap;
				// this.scene.environment = this.envMap;
				pmremGenerator.dispose();
				// initiate 3d model loading
				this.loadModel();
			});

		// setup scene, camera, lights
		this.scene = new THREE.Scene();
		this.camera = new THREE.PerspectiveCamera(45, this.canvasW / this.canvasH, 0.1, 100000);
		this.camera.position.z = this.cameraZ;

		this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);
		this.orbitControls.minDistance = this.cameraZ - 50;
		this.orbitControls.maxDistance = this.cameraZ + 50;
		

		this.scene.background = new THREE.Color(0xffffff);

		const ambientLight = new THREE.AmbientLight(this.ambientLightColor);
		ambientLight.castShadow = true;
		this.scene.add(ambientLight);
	}

	// load texture from url
	loadImage(url, id) {
		//texture.offset.set(1.0, 1.0)
		//texture.wrapS = THREE.RepeatWrapping
		//texture.wrapT = THREE.RepeatWrapping
		//texture.repeat.set( 0, 0 )
		this.textureMaterial[id].map = new THREE.TextureLoader().load(url);
	}

	// swap texture on material
	swapTexture(texture, id) {
		this.textureMaterial[id].map = texture;
	}

	changeChildColor(childName, color) {
		this.materialColor = new THREE.Color(color);

		this.object?.traverse((child) => {
			if (child.isMesh && child.name == childName) {
				child.material.color = this.materialColor;
			}
		});
	}

	// function to load base64 image
	loadBase64(base64, id) {
		if (!this.textureMaterial[id]) return;

		// Create an image
		const image = new Image(); // or document.createElement('img' )

		// Create texture
		const texture = new THREE.Texture();

		// On image load, update texture
		image.onload = () => {
			texture.image = image;
			texture.needsUpdate = true;
			this.swapTexture(texture, id);
		};

		// Set image source
		image.src = base64;
	}

	// load model (FBX or GLTF)
    loadModel() {
        const rand = Math.random() * 99999999999; // randomise to prevent cache for debugging
        const fileExtension = this.modelFilename.split('.').pop().toLowerCase();

        const onProgress = (xhr) => {
            if (xhr.lengthComputable) {
                const percentComplete = (xhr.loaded / xhr.total) * 100;
                console.log(`Model ${this.modelFilename} is ${Math.round(percentComplete)}% loaded.`);
                // Optionally, update a loading bar or status element here
            }
        };

        const onError = (error) => {
            console.error(`An error happened while loading the model: ${error}`);
            // Optionally, display an error message to the user
        };

        if (fileExtension === 'fbx') {
            const loader = new FBXLoader();
            loader.setPath(this.modelPath);
            loader.load(`${this.modelFilename}`, (object) => {
                this.handleLoadedModel(object);
            }, onProgress, onError);
        } else if (fileExtension === 'gltf' || fileExtension === 'glb') {
            const loader = new GLTFLoader();
            loader.setPath(this.modelPath);
            loader.load(`${this.modelFilename}`, (gltf) => {
                this.handleLoadedModel(gltf.scene);
            }, onProgress, onError);
        } else {
            console.error('Unsupported model format:', fileExtension);
        }

        var animate = () => {
            this.animationFrameId = requestAnimationFrame(animate);
            this.renderer.render(this.scene, this.camera);
        };

        animate();
    }
	
	handleLoadedModel(object) {
        this.object = object;
        // hide loader
        const loadingElement = document.querySelector(`#${this.elemId} .fpd-loading`);
        if (loadingElement) {
            loadingElement.remove();
        }

        // get "custom" object and customise material transparency and reflection (label mesh)
        object.traverse((child) => {
            if (child.isMesh) {
                let newMaterial;

                child.castShadow = true;
                child.receiveShadow = true;
                if (child.name.includes("custom")) {
                    const id = child.name === "custom" ? 0 : parseInt(child.name.substring(7, 8));

                    const textureObject = child;
                    const oldMaterial = textureObject.material;

                    newMaterial = new THREE.MeshStandardMaterial({
                        color: 0xffffff,
                        metalness: this.baseMaterialMetalness,
                        roughness: this.baseMaterialRoughness,
                        envMap: this.envMap,
                        envMapIntensity: 0.9,
                        map: oldMaterial.map,
                        transparent: true,
                        side: THREE.DoubleSide,
                    });

                    this.textureMaterial[id] = newMaterial;
                    textureObject.material = newMaterial;

                    const box = new THREE.Box3().setFromObject(textureObject);
                    const size = new THREE.Vector3();
                    box.getSize(size);

                    // console.debug(child.name, "Width:", size.x, "Height:", size.y, "Depth:", size.z);
                }  

                if (this.customMaterialLayers) {
                    const hasKey = this.customMaterialLayers.hasOwnProperty(child.name);
                    if (hasKey) {
                        const textureLoader = new THREE.TextureLoader();
                        textureLoader.setPath(this.modelPath); // Ensure 'this.modelPath' is correctly defined

                        // Load the normal map using the path from the customMaterialLayers object
                        const normalMap = textureLoader.load(this.customMaterialLayers[child.name]);

                        // Ensure the material supports normal maps
                        if (child.material) {
                            child.material.map = normalMap;
                            child.material.needsUpdate = true; // Ensure the material updates with the new map
                        }
                    }
                }
            }
        });

        object.position.set(0, 0, 0); // reset model position

        this.scene.add(object);
        this.modalCreated = true;

        if (this.modalLoad) {
            this.modalLoad.call();
        }
    }

	setSize(width, height) {
		this.canvasW = width;
		this.canvasH = height;

		// adjustments for mobile
		/*
		var screenW = jQuery("#"+this.elemId).width()
		if(screenW < 450){
			this.canvasW = screenW*2-2
			this.canvasH = this.canvasW/3*2
		}
*/

		this.renderer.setSize(this.canvasW, this.canvasH);

		if (this.camera) {
			this.camera.aspect = this.canvasW / this.canvasH;
			this.camera.updateProjectionMatrix();
		}

		// rendering double size for retina display
		this.renderer.domElement.style.width = this.canvasW / 2 + "px";
		this.renderer.domElement.style.height = this.canvasH / 2 + "px";
	}

	destroy() {
		// Stop animation
		if (this.animationFrameId) {
			cancelAnimationFrame(this.animationFrameId);
		}

		// Delete model from scene
		if (this.model) {
			this.scene.remove(this.model);
			// Clear memory
			this.model.geometry.dispose();
			this.model.material.dispose();
		}

		// Clear event listeners
		if (this.orbitControls) {
			this.orbitControls.dispose();
		}

		// Clear scene
		while (this.scene.children.length > 0) {
			this.scene.remove(this.scene.children[0]);
		}

		// Clear renderer
		if (this.renderer) {
			this.renderer.dispose();
			this.renderer.forceContextLoss(); // Help WebGL memory management
		}

		// Delete canvas element from DOM
		const canvasContainer = document.getElementById(this.elemId);
		if (canvasContainer && this.renderer) {
			canvasContainer.removeChild(this.renderer.domElement);
		}

		// Clear links
		this.scene = null;
		this.camera = null;
		this.orbitControls = null;
		this.renderer = null;
		this.model = null;
	}
}

export default FPD3DPreview;

if (window) window.FPD3DPreview = FPD3DPreview;
